import type { FavoriteItem } from "~/types";
import { getList, saveList } from "~/composables/useFavorite";

const _useSekouFavorite = () =>
  useState<FavoriteItem[]>("favoriteSekou", () => []);
const isLoaded = ref(false);

export default function () {
  const favoriteSekou = _useSekouFavorite();

  // 初回ロード
  onMounted(() => {
    if (!isLoaded.value) {
      fetch();
      isLoaded.value = true;
    }
  });

  // ローカルストレージから値を取得
  function fetch() {
    favoriteSekou.value = getList("sekou-favorite");
  }

  // お気に入りの追加・削除
  function toggleSekou(item: FavoriteItem) {
    // 一覧に存在しているかどうか
    if (favoriteSekou.value.find((e) => e.cd == item.cd && e.num == item.num)) {
      deleteSekou(item.cd, item.num);
    } else {
      if (addSekou(item) && process.env.NODE_ENV !== "development") {
        // 追加に成功したら　計測
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "add-favorite",
          type: "sekou",
          item_cd: item.cd,
        });
      }
    }
  }

  // お気に入りを保存する
  function addSekou(item: FavoriteItem) {
    // 配列の先頭に追加
    favoriteSekou.value.unshift(item);

    // ローカルストレージに保存して再フェッチ
    if (saveList(favoriteSekou.value, "sekou-favorite")) {
      return true;
    }
    return false;
  }

  // お気に入りを一覧から削除する
  function deleteSekou(cd: string, num?: number) {
    const item_num = num ?? 1;
    favoriteSekou.value = favoriteSekou.value.filter((e) => {
      const favNum = e.num ?? 1;
      return !(e.cd == cd && favNum == item_num);
    });
    saveList(favoriteSekou.value, "sekou-favorite");
  }

  // お気に入りのラベル編集
  function editSekou(label: string[], cd: string, num?: number) {
    const item_num = num ?? 1;
    const item = favoriteSekou.value.find((e) => {
      const favNum = e.num ?? 1;
      return e.cd == cd && favNum == item_num;
    });

    if (item) {
      item.label = label;
      saveList(favoriteSekou.value, "sekou-favorite");
    }
  }
  return {
    toggleSekou,
    deleteSekou,
    editSekou,
    favoriteSekou,
  };
}
