import type { FavoriteItem } from "~/types";
import { getList, saveList } from "~/composables/useFavorite";

const _usePlanFavorite = () =>
  useState<FavoriteItem[]>("favoritePlan", () => []);
const isLoaded = ref(false);

export default function () {
  const favoritePlan = _usePlanFavorite();

  // 初回ロード
  onMounted(() => {
    if (!isLoaded.value) {
      fetch();
      isLoaded.value = true;
    }
  });

  // ローカルストレージから値を取得
  function fetch() {
    favoritePlan.value = getList("plan-favorite");
  }

  // お気に入りの追加・削除
  function togglePlan(item: FavoriteItem) {
    // 一覧に存在しているかどうか
    if (favoritePlan.value.find((e) => e.cd == item.cd)) {
      deletePlan(item.cd);
    } else {
      if (addPlan(item) && process.env.NODE_ENV !== "development") {
        // 追加に成功したら　GTMで計測
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "add-favorite",
          type: "plan",
          item_cd: item.cd,
        });
      }
    }
  }

  // お気に入りを保存する(最大20件)
  function addPlan(item: FavoriteItem) {
    // 配列の先頭に追加
    favoritePlan.value.unshift(item);

    // ローカルストレージに保存
    if (saveList(favoritePlan.value, "plan-favorite")) {
      return true;
    }
    return false;
  }

  // お気に入りを一覧から削除する
  function deletePlan(cd: string) {
    favoritePlan.value = favoritePlan.value.filter((e) => e.cd != cd);
    saveList(favoritePlan.value, "plan-favorite");
  }

  // お気に入りのラベル編集
  function editPlan(label: string[], cd: string) {
    const item = favoritePlan.value.find((e) => {
      return e.cd == cd;
    });

    if (item) {
      item.label = label;
      saveList(favoritePlan.value, "plan-favorite");
    }
  }

  return {
    togglePlan,
    deletePlan,
    editPlan,
    favoritePlan,
  };
}
