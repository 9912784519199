// お気に入り共通関数

import type { FavoriteItem } from "~/types";

// お気に入り全体から登録済みのラベルを取得します
export function useFavoriteLabels() {
  const { favoriteSekou: s } = useSekouFavorite();
  const { favoritePlan: p } = usePlanFavorite();

  return computed(() => {
    const labels: string[] = [];
    s.value.forEach((e) => {
      e.label?.forEach((l) => labels.push(l));
    });

    p.value.forEach((e) => {
      e.label?.forEach((l) => labels.push(l));
    });

    // 重複を削除
    return [...new Set(labels)];
  });
}

// お気に入りJSONをローカルストレージから取得して配列に
export function getList(name: string): FavoriteItem[] {
  // localstorageが使えないなら空の配列を返す
  if (isActiveStorage("localStorage")) {
    try {
      const json = localStorage.getItem(name);
      // nullじゃない場合パース
      if (json !== null) return JSON.parse(json);
    } catch (e: any) {
      console.log(e.message);
    }
  }
  return [];
}

// 配列をJSONにしてローカルストレージに保存
export function saveList(list: FavoriteItem[], name: string) {
  if (isActiveStorage("localStorage")) {
    try {
      localStorage.setItem(name, JSON.stringify(list));
      return true;
    } catch (e: any) {
      console.log(e.message);
    }
  }
  return false;
}
